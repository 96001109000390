import React from 'react'

import { Container } from 'reactstrap'
import SEO from "../../components/seo"
import Slider from '../../components/slider-contact'
import LayoutEsp from '../../components/layoutEsp'
import worldIcon from '../../assets/images/world.png'
import redValue from '../../assets/images/red-star.png' 
import bluePhone from '../../assets/images/bluePhone.png' 
import blueMail from '../../assets/images/blueMail.png' 
import bluePoint from '../../assets/images/bluePoint.png' 
 
export default () => (

    <LayoutEsp> 
      <SEO title="Contacto" />
      <Slider title="CONTACTO"/>
		 <div className="world-brand" >
			<Container className="pt-4">
				<div className="row">
				  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
					<img className="world-icon" src={worldIcon} alt="worldIcon"/>
				  </div>
				  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
				  </div>
				</div>
			</Container>
		</div>
		<div className="point-crossed-background-right" >
			<Container className="pt-4">
				<div className="row block">
				  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
					<img className="redValue" src={redValue} alt="red icon value"/> 
					<h4 className="contactH"><b>CONTACTO</b></h4> 
					<p className="contactP">{'Oficinas en Mexicali y Calexico'}</p>  
				  </div>
				  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 contactBlock">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contactIcons">
							<h4 className="grayH">{' '}</h4> 
							 <div className="row"> 
								<div className="col-lg-5 col-md-5 col-sm-6 col-xs-6 contactImage">
									<div className="row contact-white">  
										<div className="contact-white-icon">
											<img className="bluePhone" src={bluePhone} alt="blue icon phone"/> 
										</div> 
										<div className="contact-white-text">
											<h4 className="contact-white-H"><b>Tel:</b></h4>
											<p className="contact-white-P">MX 686 5 64 8229</p>
											<p className="contact-white-P">US 760 3 57 0991</p>
										</div>
									</div>
								</div> 
								<div className="col-lg-7 col-md-7 col-sm-6 col-xs-6 contactImage">
									<div className="row contact-white">  
										<div className="contact-white-icon">
											<img className="bluePhone" src={blueMail} alt="blue icon mail"/> 
										</div> 
										<div className="contact-white-text">
											<h4 className="contact-white-H"><b>E-mail:</b></h4>
											<p className="contact-white-P">Quotes@gutierreztrucking.com</p>
											<p className="contact-white-P">Admin@gutierreztrucking.com</p>
										</div>
									</div>
								</div> 
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contactImage">
									<div className="row contact-white">  
										<div className="contact-white-icon">
											<img className="bluePhone" src={bluePoint} alt="blue icon phone"/> 
										</div> 
										<div className="contact-white-text addressBlock">
											<h4 className="contact-white-H"><b>Direccion:</b></h4>
											<p className="contact-white-P">Km 11.5 Carretera Aeropuerto, Col. Mariano Abasolo Mexicali, B.C. CP 21600</p> 
										</div>
									</div>
								</div> 
								<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contactMapDiv">
									<div className="row contactMapBlock">
										<iframe title="map" className="contactMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3359.3904994566515!2d-115.33559028482111!3d32.64905108101081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d773a7a8805695%3A0x50408883e9f49b0!2sTransportes%20Internacionales%20Escoboza!5e0!3m2!1sen!2smx!4v1602264224035!5m2!1sen!2smx"/>
									</div>
								</div> 
							</div> 
						</div> 
					</div>
				  </div>
				</div>
			</Container>
		</div>
    </LayoutEsp>
)